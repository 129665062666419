import React from 'react';
import * as actionTypes from "../actionTypes";
import { gql } from "apollo-boost";
import { Encrypt } from "../../shared/encrpt";
import axios from "axios";
import Server from "../../../Common/Server";
import * as actions from "../index";
import notificationMsg from '../../../App/view/Layout/NotificationMessage';
import * as ChangeAppLanguageFront from '../../../App/view/Layout/ChangeAppLanguageFront';
let tlang = ChangeAppLanguageFront.translateLanguage;

const AdvanceProbingCreated = tlang('notificationSuccess_AdvanceProbingCreated') || 'Advance probing created successfully';
const ProbingCreated = tlang('notificationSuccess_ProbingCreated') || 'Probing Created Successfully';
const ProbingUpdated = tlang('notificationSuccess_ProbingUpdated') || 'Probing Updated Successfully';
const ProbingDeleted = tlang('notificationSuccess_ProbingDeleted') || 'Probing Deleted Successfully';
const ProbingRestored = tlang('notificationSuccess_ProbingRestored') || 'Probing Restored Successfully';
const ProbingClosed = tlang('notificationSuccess_ProbingClosed') || 'Probing Closed Successfully';
const ProbingReOpen = tlang('notificationSuccess_ProbingReOpen') || 'Probing Reopen Successfully';
const error_something_wrong = tlang('error_something_wrong') || 'Something Went Wrong!';
const fileupload = tlang('notificationSuccess_excelUpload_fileupload') || 'File uploaded sucessfully';
const ProbingImport = tlang('notification_success_save_import_probing_excel') || 'Probing Import Successfully';
const SampleDisposalSettingUpdated = tlang('notification_success_sample_disposal_setting_updated') || 'Data Updated Successfully';
const creating_account_reviewed_msg = tlang('other_notification_sucess_your_request_for_creating_account_has_been_reviewed') || 'Your request for creating account has been received. You will get an email on your email from registration@aexdo.com once the request has been reviewed';
const probingexcelsheet_deleted_successfully = tlang('notificationSuccess_delete_excelsheet') || 'Data Deleted Successfully';


// notification Success
const notificationSuccess = msg => {
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_SUCCESS,
    state: msg
  };
};

// notification Fail
const notificationFail = err => {
  let msg = error_something_wrong;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    // msg = err.graphQLErrors[0].message;
    let translateMsg = notificationMsg(err.graphQLErrors[0].statusCode)
    msg = translateMsg;
  } else if (err.message) {
    msg = err.message;
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// get Create Probing List Success
const getCreateProbingListSuccess = data => {
  return {
    type: actionTypes.GET_CREATE_PROBING_LIST,
    state: data
  };
};

//create new probing
export const createNewProbing = (probing, request) => {
  return dispatch => {
    const mutation = gql`
      mutation create_probing(
        $probingcode: String!
        $probingname: String!
        $campaignid: String!
        $projectid: String!
        $companyid: String!
        $probingDate: String!
        $latitude: String
        $longitude: String
        $num_code: String!
      ) {
        create_probing(
          probingcode: $probingcode
          probingname: $probingname
          campaignid: $campaignid
          projectid: $projectid
          probingDate: $probingDate
          companyid: $companyid
          latitude: $latitude
          longitude: $longitude
          num_code: $num_code
        ) {
          _id
          probingid
          probingname
          probingcode
          longitude
          latitude
          campaignid
          projectid
        }
      }
    `;
    probing
      .mutate({
        mutation: mutation,
        variables: {
          probingcode: Encrypt(request.probingcode),
          probingname: Encrypt(request.probingname),
          campaignid: Encrypt(request.campaignid),
          projectid: Encrypt(request.projectid),
          companyid: Encrypt(request.companyid),
          probingDate: Encrypt(request.probingDate),
          latitude: Encrypt(request.latitude),
          longitude: Encrypt(request.longitude),
          num_code: Encrypt(request.num_code)
        }
      })
      .then(response => {
        dispatch(notificationSuccess(ProbingCreated));
        dispatch(getCreateProbingListSuccess(response.data.create_probing));
        let listParam = {
          projectid: request.projectid,
          campaignid: request.campaignid
        };
        dispatch(actions.getProbingList(probing, listParam));

        let detailsParam = {
          probingid: response.data.create_probing.probingid
        };
        dispatch(actions.getProbingDetail(probing, detailsParam));
      })
      .catch(error => {
        // if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].statusCode == 203) {
        //   dispatch(getCreateProbingListSuccess('probingexisted'));
        // }
        dispatch(notificationFail(error));
      });
  };
};

// get Probing List Success
const getProbingListSuccess = data => {
  return {
    type: actionTypes.GET_PROBING_LIST,
    state: data
  };
};
// get Probing List Success for table csv
const getProbingListSuccessTableCsv = data => {
  return {
    type: actionTypes.GET_PROBING_LIST_TABLE_CSV,
    state: data
  };
};

// get get_mapwise_probing_lis
const getMapwiseProbingListReport = data => {
  return {
    type: actionTypes.GET_MAPWISE_PROBING_LIST_REPORT,
    state: data
  };
};
// get Probing todo log  List Success
const getProbingTodoLogListSuccess = data => {
  return {
    type: actionTypes.GET_PROBING_TODO_LOG_LIST,
    state: data
  };
};
// get camping todo log  List Success
const getCampaignTodoLogListSuccess = data => {
  return {
    type: actionTypes.GET_CAMPAIGN_TODO_LOG_LIST,
    state: data
  };
};

// get probing list
export const getProbingList = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_probing_list($projectid: String, $campaignid: String) {
        get_probing_list(projectid: $projectid, campaignid: $campaignid) {
          _id
          probingid
          probingname
          probingcode
          campaignid
          projectid
          companyid
          longitude
          latitude
          apparentdepth
          actualdepth
          nooflayer
          noofsample
          status
          startdate
          enddate
          onsiteExpertName
          fieldEngineer
          probingDate
          probingTool
          firstname
          lastname
          contactnumber
          layer
          campaignname
          endstatus
          inputtext
          altitude
          expecteddepth
          remark
          probingaudio
          probingimage
          num_code
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          campaignid: Encrypt(request.campaignid)
        }
      })
      .then(response => {
        dispatch(getProbingListSuccess(response.data.get_probing_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};


export const probingListForView = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_probing_list($projectid: String, $campaignid: String) {
        get_probing_list(projectid: $projectid, campaignid: $campaignid) {
          campaignname
          probingcode
          inputtext
          nooflayer
          noofsample
          startdate
          probingDate
          status
          longitude
          latitude
          endstatus
          _id
          probingid
          probingname
          probingcode
          campaignid
          projectid
          companyid
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          campaignid: Encrypt(request.campaignid)
        }
      })
      .then(response => {
        dispatch(getProbingListSuccess(response.data.get_probing_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};



// get probing list
export const probingListForProbingAnalysis = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_probing_list($projectid: String, $campaignid: String) {
        get_probing_list(projectid: $projectid, campaignid: $campaignid) {
          probingcode
          probingname
          campaignid
          altitude
          layer
          longitude
          latitude
          status
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          campaignid: Encrypt(request.campaignid)
        }
      })
      .then(response => {
        dispatch(getProbingListSuccess(response.data.get_probing_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};


// get probing list
export const multipleCampaignProbingListForProbingAnalysis = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_mapwise_probing_list($projectid: String!, $fil_type: String!, $campaignid_arr: String, $probingid_arr: String, $need_attach: Boolean) {
        get_mapwise_probing_list(projectid: $projectid, fil_type: $fil_type, campaignid_arr: $campaignid_arr, probingid_arr: $probingid_arr, need_attach: $need_attach) {
          probingid
          probingcode
          altitude
          layer
          longitude
          latitude
          status
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          fil_type: Encrypt(request.fil_type),
          campaignid_arr: Encrypt(request.campaignid_arr),
          probingid_arr: Encrypt(request.probingid_arr),
          need_attach: request.need_attach
        }
      })
      .then(response => {
        dispatch(getProbingListSuccess(response.data.get_mapwise_probing_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};



// get Probing Detail Success
const getProbingDetailSuccess = data => {
  return {
    type: actionTypes.GET_PROBING_DETAIL,
    state: data
  };
};

// get probing detail
export const getProbingDetail = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_probing_detail($probingid: String!) {
        get_probing_detail(probingid: $probingid) {
          _id
          probingid
          probingname
          probingcode
          campaignid
          projectid
          companyid
          longitude
          latitude
          apparentdepth
          actualdepth
          onsiteExpertName
          fieldEngineer
          probingDate
          firstname
          lastname
          contactnumber
          campaignname
          nooflayer
          noofsample
          status
          startdate
          enddate
          layer
          endstatus
          inputtext
          altitude
          expecteddepth
          remark
          probingaudio
          probingimage
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          probingid: Encrypt(request.probingid)
        }
      })
      .then(response => {
        dispatch(getProbingDetailSuccess(response.data.get_probing_detail));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

//upload file
const uploadFile = async (data, method) => {
  return new Promise(async (resolve, reject) => {
    axios
      .post(Server.ImageAPI + method, data)
      .then(function (response) {
        resolve(
          creating_account_reviewed_msg
        );
      })
      .catch(function (error) {
        resolve(
          creating_account_reviewed_msg
        );
      });
  });
};

// update probing
export const updateProbing = (probing, request) => {
  let requestParam = {
    projectid: request.projectid,
    //campaignid: request.listCampaignid
    campaignid: ""
  };
  delete request["listCampaignid"];
  return dispatch => {
    const mutation = gql`
      mutation update_probing(
        $probingid: String!
        $probingname: String
        $probingcode: String!
        $campaignid: String!
        $projectid: String!
        $companyid: String!
        $latitude: String!
        $longitude: String!
        $apparentdepth: String
        $actualdepth: String
        $onsiteExpertName: String
        $fieldEngineer: String
        $probingDate: String
        $firstname: String
        $lastname: String
        $contactnumber: String
        $status: String!
        $layer: String!
        $inputText: String
        $altitude: String
        $expectedDepth: String
        $remark: String
        $endstatus: Boolean!
      ) {
        update_probing(
          probingid: $probingid
          probingname: $probingname
          probingcode: $probingcode
          campaignid: $campaignid
          projectid: $projectid
          companyid: $companyid
          latitude: $latitude
          longitude: $longitude
          apparentdepth: $apparentdepth
          actualdepth: $actualdepth
          onsiteExpertName: $onsiteExpertName
          fieldEngineer: $fieldEngineer
          probingDate: $probingDate
          firstname: $firstname
          lastname: $lastname
          contactnumber: $contactnumber
          status: $status
          layer: $layer
          inputText: $inputText
          altitude: $altitude
          expectedDepth: $expectedDepth
          remark: $remark
          endstatus: $endstatus
        ) {
          _id
          probingid
          probingname
          probingcode
          campaignid
          projectid
          companyid
          longitude
          latitude
          apparentdepth
          onsiteExpertName
          actualdepth
          fieldEngineer
          probingDate
          nooflayer
          noofsample
          status
          startdate
          enddate
          layer
          inputtext
          altitude
          expecteddepth
          remark
        }
      }
    `;
    probing
      .mutate({
        mutation: mutation,
        variables: {
          probingid: Encrypt(request.probingid),
          probingname: Encrypt(request.probingname),
          probingcode: Encrypt(request.probingcode),
          campaignid: Encrypt(request.campaignid),
          projectid: Encrypt(request.projectid),
          companyid: Encrypt(request.companyid),
          latitude: Encrypt(request.latitude),
          longitude: Encrypt(request.longitude),
          apparentdepth: Encrypt(request.apparentdepth),
          actualdepth: Encrypt(request.actualdepth),
          onsiteExpertName: Encrypt(request.onsiteExpertName),
          fieldEngineer: Encrypt(request.fieldEngineer),
          probingDate: Encrypt(request.probingDate),
          firstname: Encrypt(request.firstname),
          lastname: Encrypt(request.lastname),
          contactnumber: Encrypt(request.number),
          status: Encrypt(request.status),
          layer: Encrypt(request.layer),
          inputText: Encrypt(request.inputtext),
          altitude: Encrypt(request.altitude),
          expectedDepth: Encrypt(request.expecteddepth),
          remark: Encrypt(request.remark),
          endstatus: request.endstatus ? request.endstatus : false
        }
      })
      .then(response => {
        let images = request.files ? request.files : [];
        let files = [];
        if (images.length > 0) {
          images.map((img) => {
            //if (img.preview) {
            files.push(img);
            //}
          })
        }
        let probing_images = request.probing_images ? request.probing_images : [];
        let probing_files = [];
        if (probing_images.length > 0) {
          probing_images.map((pimg) => {
            if (pimg.preview) {
              probing_files.push(pimg);
            }
          })
        }
        let detailsParam = {
          probingid: request.probingid
        };

        if (files.length > 0 || probing_files.length > 0) {
          /* upload probing layer images */
          if (files.length > 0) {
            var data = new FormData();
            data.append("companyid", response.data.update_probing.probingid);
            files.map(val => {
              if (val.file.type) {
                data.append("files", val.file, val.layerindex + "-" + val.file.name);
              }
            });
            uploadFile(data, "insert_probing_attechment/")
              .then(response => {
                dispatch(actions.getProbingDetail(probing, detailsParam));
              })
              .catch(err => {
                dispatch(notificationFail(err));
              });
          }
          /* upload probing images */
          if (probing_files.length > 0) {
            var data = new FormData();
            data.append("probingid", response.data.update_probing.probingid)
            probing_files.map(val => {
              if (val.type) {
                data.append("files", val);
              }
            });
            uploadFile(data, "insert_probing_attach_files/")
              .then(response => {
                dispatch(actions.getProbingDetail(probing, detailsParam));
              })
              .catch(err => {
                dispatch(notificationFail(err));
              });
          }
        } else {
          dispatch(actions.getProbingDetail(probing, detailsParam));
        }
        dispatch(actions.probingListForView(probing, requestParam));
        dispatch(notificationSuccess(ProbingUpdated));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// delete probing list
export const deleteProbing = (probing, request) => {
  let requestParam = {
    projectid: request.projectid,
    // campaignid: request.campaignid
    campaignid: "" // changes Solve filter issue 
  };
  return dispatch => {
    const query = gql`
      query delete_probing($probingid: String!, $delete_type: String! ,$is_permanent_delete: Boolean!) {
        delete_probing(probingid: $probingid, delete_type: $delete_type ,is_permanent_delete: $is_permanent_delete) {
          status
          message
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          probingid: Encrypt(request.probingid),
          delete_type: Encrypt(request.probingStatus),
          is_permanent_delete: request.permanetDeleteStatus
        }
      })
      .then(response => {
        dispatch(actions.probingListForView(probing, requestParam));
        if (request.probingStatus == 'Delete') {
          dispatch(notificationSuccess(ProbingDeleted)); // delete probing
        } else {
          dispatch(notificationSuccess(ProbingRestored)); // restore probing
        }
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// delete probing while importing excel sheet with the same ids
export const deleteProbingwhileImportProbing = (probing, request) => {
  console.log("requestPARAMAS",request)
  return dispatch => {
    const query = gql`
      query delete_multiple_probings($probingids: String!,$is_permanent_delete: Boolean!) {
        delete_multiple_probings(probingids: $probingids ,is_permanent_delete: $is_permanent_delete) {
          status
          message
        }
      }
    `;
   return probing
      .query({
        query: query,
        variables: {
          probingids: Encrypt(JSON.stringify(request.probingids)),
          is_permanent_delete: request.permanetDeleteStatus
        }
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// close probing 
export const closeProbing = (probing, request) => {
  let requestParam = {
    projectid: request.projectid,
    //campaignid: request.campaignidForList
    campaignid: ''
  };
  return dispatch => {
    const mutation = gql`
      mutation end_probing($probingid: String!, $reasonid: String!, $reasonname: String!, $detail: String!, $endstatus: Boolean!) {
        end_probing(probingid: $probingid, reasonid: $reasonid, reasonname: $reasonname, detail: $detail, endstatus: $endstatus) {
          status
          message
        }
      }
    `;
    probing
      .mutate({
        mutation: mutation,
        variables: {
          probingid: Encrypt(request.probingid),
          reasonid: Encrypt(request.reasonid),
          reasonname: Encrypt(request.reasonname),
          detail: Encrypt(request.detail),
          endstatus: request.endstatus
        }
      })
      .then(response => {
        let detailsParam = {
          probingid: request.probingid
        };
        dispatch(actions.getProbingDetail(probing, detailsParam));
        dispatch(actions.probingListForView(probing, requestParam));
        dispatch(notificationSuccess(request.endstatus ? ProbingClosed : ProbingReOpen));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// create  multiple probing todo history 
export const createAdvanceProbings = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation create_probing_advance($probings: String!) {
        create_probing_advance(probings: $probings) {
          status
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          probings: Encrypt(request.probings),
        }
      })
      .then(response => {
        dispatch(notificationSuccess(AdvanceProbingCreated));
        const probData = JSON.parse(request.probings);
        const req = {
          projectid: probData[0].projectid,
          campaignid: probData[0].campaignid
        };
        dispatch(actions.getProbingList(client, req));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// create probing todo history 
export const createProbingTodoLog = (client, request) => {
  let requestParam = {
    projectid: request.projectid,
    campaignid: request.campaignid
  };
  return dispatch => {
    const mutation = gql`
      mutation create_probing_todo_log($projectid: String!, $campaignid: String!, $probingid: String!, $todo_details: String!) {
        create_probing_todo_log(projectid: $projectid, campaignid: $campaignid, probingid: $probingid, todo_details: $todo_details) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          projectid: Encrypt(request.projectid),
          campaignid: Encrypt(request.campaignid),
          probingid: Encrypt(request.probingid),
          todo_details: Encrypt(request.todo_details),
        }
      })
      .then(response => {
        var req = {
          probingid: request.probingid,
        }
        dispatch(actions.getProbingTodoLogList(client, req));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};
// create  multiple probing todo history 
export const createMultipleProbingTodoLog = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation create_multiple_probing_todo_log($todo_log_array: String!) {
        create_multiple_probing_todo_log(todo_log_array: $todo_log_array) {
          status
          message
        }
      }
    `;
    client
      .mutate({
        mutation: mutation,
        variables: {
          todo_log_array: Encrypt(request.todo_log_array),
        }
      })
      .then(response => {
        if (request.probingid) {
          var req = {
            probingid: request.probingid,
          }
          dispatch(actions.getProbingTodoLogList(client, req));
        }
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};


// get get_probing_todo_log_list
export const getProbingTodoLogList = (probing, request) => {
  let requestParam = {
    projectid: request.projectid,
    campaignid: request.campaignid
  };
  return dispatch => {
    const query = gql`
      query get_probing_todo_log_list($probingid: String!) {
        get_probing_todo_log_list(probingid: $probingid) {
          _id
          projectid
          campaignid
          probingid
          userid
          todo_details
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          probingid: Encrypt(request.probingid)
        }
      })
      .then(response => {
        dispatch(getProbingTodoLogListSuccess(response.data.get_probing_todo_log_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};
// get campaign probing lodo log list
export const getCampaignProbingTodoLogList = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_campaign_probing_todo_log_list($campaignid: String!) {
        get_campaign_probing_todo_log_list(campaignid: $campaignid) {
          _id
          projectid
          campaignid
          probingid
          userid
          todo_details
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          campaignid: Encrypt(request.campaignid)
        }
      })
      .then(response => {
        dispatch(getCampaignTodoLogListSuccess(response.data.get_campaign_probing_todo_log_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// update multiple probing
export const addEditCampaignProbing = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation add_edit_campaign_probing ($companyname: String!, $req_data: String!) {
        add_edit_campaign_probing(companyname: $companyname, req_data: $req_data) {
          status:
          error
          msg
        }
      }
  `;
    client.mutate({
      mutation: mutation,
      variables: {
        companyname: request.companyname,
        req_data: request.req_data
      }
    })
      .then(response => {
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// get probing list
export const getProbingListDashboard = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_probing_list {
        get_probing_list{
          _id
          probingid
          probingname
          probingcode
          campaignid
          projectid
          companyid
          longitude
          latitude
          apparentdepth
          actualdepth
          nooflayer
          noofsample
          status
          startdate
          enddate
          onsiteExpertName
          fieldEngineer
          probingDate
          probingTool
          firstname
          lastname
          contactnumber
          layer
          campaignname
          endstatus
          inputtext
          altitude
          expecteddepth
          remark
          probingaudio
          probingimage
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {}
      })
      .then(response => {
        dispatch(getProbingListSuccess(response.data.get_probing_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};



// upload probing excel file
export const uploadProbingExcelFile = (client, request) => {
  var formData = new FormData();
  formData.append("files", request.file);
  formData.append("sheet", request.data.sheet);
  formData.append("row", request.data.row);
  formData.append("rownumber", request.data.rownumber);
  formData.append("check", request.check);
  formData.append("companyid", request.companyid);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return (dispatch) => {
    axios
      .post(Server.ImageAPI + "upload_probing_sheet", formData, config)
      .then((result) => {
        if (result.data.error) {
          let translateMsg = notificationMsg(result.data.statusCode);
          dispatch(notificationSheetFail(translateMsg));
        } else {
          dispatch(uploadProbingExcelSuccess(result.data));
          dispatch(notificationSuccess(fileupload));
        }
      })
      .catch((error) => {
        dispatch(notificationSheetFail(error_something_wrong));
      });
  };
};

// notification Sheet Fail
const notificationSheetFail = err => {
  let msg = error_something_wrong;
  if (err && err != "") {
    msg = err
  }
  return {
    type: actionTypes.ADD_NEW_NOTIFICATION_FAIL,
    state: msg
  };
};

// upload Excel Success
const uploadProbingExcelSuccess = data => {
  return {
    type: actionTypes.UPLOAD_PROBING_EXCEL,
    state: data
  };
};

// upload Excel Success
const importProbingExcelDataSuccess = data => {
  return {
    type: actionTypes.SAVE_IMPORT_PROBING_EXCEL,
    state: data
  };
};

// import probing excel data
export const importProbingExcelData = (client, request) => {
  console.log("Requuuuesssttttt",request)
  return dispatch => {
    const mutation = gql`
      mutation import_probing ($campaignid: String!, $campaign_name: String!, $probing_data: String!, $projectid: String!, $companyid: String!, $file_name: String!, $fileName: String!, $sheet_name: String!, $rows_for_head_probing: String!, $row_for_head_probing_no: String!, $sheet_resp_header: String!, $sheet_resp_data: String!) {
        import_probing(campaignid: $campaignid, campaign_name: $campaign_name, probing_data: $probing_data, projectid: $projectid, companyid: $companyid, file_name: $file_name, fileName: $fileName, sheet_name: $sheet_name, rows_for_head_probing: $rows_for_head_probing, row_for_head_probing_no: $row_for_head_probing_no, sheet_resp_header: $sheet_resp_header, sheet_resp_data: $sheet_resp_data) {
          success
          error
          msg
          probingsInfo
        }
      }
  `;
    client.mutate({
      mutation: mutation,
      variables: {
        campaignid: Encrypt(request.campaignid),
        campaign_name: Encrypt(request.campaign_name),
        probing_data: Encrypt(request.probing_data),
        projectid: Encrypt(request.projectid),
        companyid: Encrypt(request.companyid),
        file_name: Encrypt(request.file_name),
        fileName: Encrypt(request.fileName),
        sheet_name: Encrypt(request.sheet_name),
        rows_for_head_probing: Encrypt(request.rows_for_head_probing),
        row_for_head_probing_no: Encrypt(request.row_for_head_probing_no),
        sheet_resp_header: Encrypt(request.sheet_resp_header),
        sheet_resp_data: Encrypt(request.sheet_resp_data),
      }
    })
      .then(response => {
        dispatch(importProbingExcelDataSuccess(response.data.import_probing));
        dispatch(notificationSuccess(ProbingImport));
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// create bulk material for import probing
export const createBulkMaterial = (client, request) => {

  let otherMaterialsParams = {
    type_list: request.type_list,
    lang_code: request.lang_code
  }

  return dispatch => {
    const mutation = gql`
      mutation create_bulk_material ($master_data: String!) {
        create_bulk_material(master_data: $master_data) {
          message
          error
          success
        }
      }
  `;
    client.mutate({
      mutation: mutation,
      variables: {
        master_data: Encrypt(request.master_data)
      }
    })
      .then(response => {
        dispatch(actions.getTypeWiseOtherMatreials(client, otherMaterialsParams));
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// get probing list for table csv
export const getProbingListTableCsv = (probing, request) => {
  return dispatch => {
    const query = gql`
    query get_mapwise_probing_list($projectid: String!, $fil_type: String!, $campaignid_arr: String, $probingid_arr: String, $need_attach: Boolean,$originid_arr:String,$destinationsid_arr:String,$boundariesid_arr:String,$poll_zonesid_arr:String,$need_pending_probing: Boolean) {
        get_mapwise_probing_list(projectid: $projectid, fil_type: $fil_type, campaignid_arr: $campaignid_arr, probingid_arr: $probingid_arr, need_attach: $need_attach,originid_arr:$originid_arr,destinationsid_arr:$destinationsid_arr,boundariesid_arr:$boundariesid_arr,poll_zonesid_arr:$poll_zonesid_arr,need_pending_probing:$need_pending_probing) {
          _id
          probingid
          probingname
          probingcode
          probingDate
          startdate
          campaignid
          projectid
          companyid
          longitude
          latitude
          noofsample
          altitude
          expecteddepth
          fieldEngineer
          remark
          firstname
          lastname
          contactnumber
          layer
          inputtext
          status
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          fil_type: Encrypt(request.fil_type),
          campaignid_arr: Encrypt(request.campaignid_arr),
          probingid_arr: Encrypt(request.probingid_arr),
          need_attach: false,
          originid_arr: Encrypt(request.originid_arr),
          destinationsid_arr: Encrypt(request.destinationsid_arr),
          boundariesid_arr: Encrypt(request.boundariesid_arr),
          poll_zonesid_arr: Encrypt(request.poll_zonesid_arr),
          need_pending_probing: request.need_pending_probing
        }
      })
      .then(response => {
        dispatch(getProbingListSuccessTableCsv(response.data.get_mapwise_probing_list));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// get probing list for probing report
export const getMapwiseProbingList = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_mapwise_probing_list($projectid: String!, $fil_type: String!, $campaignid_arr: String, $probingid_arr: String, $need_attach: Boolean,$originid_arr:String,$destinationsid_arr:String,$boundariesid_arr:String,$poll_zonesid_arr:String) {
        get_mapwise_probing_list(projectid: $projectid, fil_type: $fil_type, campaignid_arr: $campaignid_arr, probingid_arr: $probingid_arr, need_attach: $need_attach,originid_arr:$originid_arr,destinationsid_arr:$destinationsid_arr,boundariesid_arr:$boundariesid_arr,poll_zonesid_arr:$poll_zonesid_arr) {
           _id
          probingid
          probingname
          probingcode
          campaignid
          projectid
          companyid
          longitude
          latitude
          apparentdepth
          actualdepth
          onsiteExpertName
          fieldEngineer
          probingDate
          firstname
          lastname
          contactnumber
          campaignname
          nooflayer
          noofsample
          status
          startdate
          enddate
          layer
          endstatus
          inputtext
          altitude
          expecteddepth
          remark
          probingaudio
          probingimage
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid),
          fil_type: Encrypt(request.fil_type),
          campaignid_arr: Encrypt(request.campaignid_arr),
          probingid_arr: Encrypt(request.probingid_arr),
          need_attach: false,
          originid_arr: Encrypt(request.originid_arr),
          destinationsid_arr: Encrypt(request.destinationsid_arr),
          boundariesid_arr: Encrypt(request.boundariesid_arr),
          poll_zonesid_arr: Encrypt(request.poll_zonesid_arr),
        }
      })
      .then(response => {
        if (response.data) {
          dispatch(getMapwiseProbingListReport(response.data.get_mapwise_probing_list));
        }
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// Create Forcefully selected Disposal to samples
export const createForcedDisposalToSample = (client, request) => {
  return dispatch => {
    const mutation = gql`
      mutation create_forced_disposal_to_sample ($userid: String!, $projectid: String!, $default_disposal_type: String!, $default_disposal_list: String!, $forced_sampledata: String!, $id: String) {
        create_forced_disposal_to_sample(userid: $userid, projectid: $projectid, default_disposal_type: $default_disposal_type, default_disposal_list: $default_disposal_list, forced_sampledata: $forced_sampledata, id: $id) {
          success
          error
          message
        }
      }
  `;
    client.mutate({
      mutation: mutation,
      variables: {
        userid: Encrypt(request.userid),
        projectid: Encrypt(request.projectid),
        default_disposal_type: Encrypt(request.default_disposal_type),
        default_disposal_list: Encrypt(request.default_disposal_list),
        forced_sampledata: Encrypt(request.forced_sampledata),
        id: Encrypt(request.id),
      }
    })
      .then(response => {
        dispatch(notificationSuccess(SampleDisposalSettingUpdated));
        const requestParam = {
          userid: request.userid,
          projectid: request.projectid,
          default_disposal_type: request.default_disposal_type,
          default_disposal_list: request.default_disposal_list,
        }
        dispatch(actions.getForcedDisposalToSample(client, requestParam));
      }).catch((error) => {
        dispatch(notificationFail(error))
      });
  }
}

// upload Excel Success
const getForcedDisposalToSampleSuccess = data => {
  return {
    type: actionTypes.GET_FORCED_DISPOSAL_TO_SAMPLE,
    state: data
  };
};

// Get Forcefully selected Disposal to samples
export const getForcedDisposalToSample = (probing, request) => {
  return dispatch => {
    const query = gql`
      query get_forced_disposal_to_sample($userid: String!, $projectid: String!, $default_disposal_type: String!, $default_disposal_list: String!) {
        get_forced_disposal_to_sample(userid: $userid, projectid: $projectid, default_disposal_type: $default_disposal_type, default_disposal_list: $default_disposal_list) {
          _id
          userid
          projectid
          email
          company_name
          default_disposal_type
          default_disposal_list
          forced_sampledata
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          userid: Encrypt(request.userid),
          projectid: Encrypt(request.projectid),
          default_disposal_type: Encrypt(request.default_disposal_type),
          default_disposal_list: Encrypt(request.default_disposal_list),
        }
      })
      .then(response => {
        if (response.data) {
          dispatch(getForcedDisposalToSampleSuccess(response.data.get_forced_disposal_to_sample));
        }
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};


// get Excel Data Success
const getProbingImportExcelDataSuccess = data => {
  return {
    type: actionTypes.GET_PROBING_EXCEL_DATA,
    state: data
  };
};

// get Probing Excel Data List
export const getProbingImportExcelList = (probing, request) => {

  return dispatch => {
    const query = gql`
      query get_probing_import_sheet_data($projectid: String!) {
        get_probing_import_sheet_data(projectid: $projectid) { 
          _id
          projectid
          campaignid
          companyid
          file_name
          fileName
          url
          sheet_name
          rows_for_head_probing
          row_for_head_probing_no
          sheet_resp_header
          sheet_resp_data
          created_at
        }
      }
    `;
    probing
      .query({
        query: query,
        variables: {
          projectid: Encrypt(request.projectid)
        }
      })
      .then(response => {
        dispatch(getProbingImportExcelDataSuccess(response.data.get_probing_import_sheet_data));
      })
      .catch(error => {
        dispatch(notificationFail(error));
      });
  };
};

// delete Probing Excel Sheet
export const deleteProbingImportExcel = (probing, request) => {
  return dispatch => {
    const mutation = gql`
        mutation delete_probing_import_sheet_data (
          $id: String!
          ) {
            delete_probing_import_sheet_data (
              id: $id
              ) {
            message
            error
            success
          }
        }
        `;
    probing.mutate({
      mutation: mutation,
      variables: {
        id: Encrypt(request.id)
      }
    }).then(response => {
      if (response.data.delete_probing_import_sheet_data.error === false) {
        dispatch(notificationSuccess(probingexcelsheet_deleted_successfully))
      } else {
        dispatch(notificationFail(response.data.delete_probing_import_sheet_data.message))
      }
    }).catch((error) => {
      dispatch(notificationFail(error))
    });
  }
}